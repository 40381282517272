import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAF9F7',
      neutralLight3: '#F2F4F7',
      neutralLight2: '#F1EFE8',
      neutralDark4: '#BEC3D3',
      neutralDark3: '#A0A0A0',
      neutralDark2: '#585653',
      neutralDark1: '#222223',
      primaryLight: '#192240',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: 'itc-avant-garde-gothic-pro, sans-serif',
    paragraph: "'dashiell-fine', sans-serif",
    label: 'thonglor, sans-serif',
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark1};
  font-family: ${theme.fontFamily.heading};
  font-variation-settings: 'wght' 300;
  text-transform: uppercase;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark1};
    font-family: ${theme.fontFamily.label};
    font-size: 0.8125rem;
    letter-spacing: 0.025em;
    line-height: 1.375rem;
    margin-bottom: 2.25rem;
    text-transform: uppercase;

    @media (max-width: 1199px) {
      margin-bottom: 1rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 4.5625rem;
      font-variation-settings: 'wght' 400;
      line-height: 5.75rem;

      @media (max-width: 1199px) {
        font-size: 3.75rem;
        font-variation-settings: 'wght' 300;
        line-height: 4rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.625rem;

      @media (max-width: 1199px) {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.375rem;

      @media (max-width: 74.9375rem) {
        font-size: 2.25rem;
        line-height: 2.625rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-weight: 300;
      font-size: 2rem;
      line-height: 2.625rem;

      @media (max-width: 74.9375rem) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-weight: 300;
      font-size: 1.625rem;
      line-height: 2.25rem;

      @media (max-width: 74.9375rem) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark2};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5625rem;

    @media (max-width: 1199px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
